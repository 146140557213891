<template>
    <div class="template-page">
        default
    </div>
</template>

<script>

export default {
  components: {  },
    name: 'default',
    data: () => {
        return {
        }
    },
    methods: {
    },
    computed: {
    },
    mounted() {
    }
}
</script>

<style lang="scss" scoped>
</style>